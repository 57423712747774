<template>
  <div style="background:white;overflow:hidden">
    <div class="card"
         v-if="form.if_is_head == 0">
      <div class="card_head">
        <span>题目编号：{{form.question_id}}</span> 
        <!-- 题目分数:<span style="color:#FF6147">{{form.question_score}}</span> -->
        <span v-if="$route.path == '/teacher_module/taskList'"
              @click="jiucuo"
              class="jiucuo">纠错</span>
      </div>
      <div class="box-card">
        <div class="clearfix">
          <span>题目内容：</span>
        </div>
        <div>
          <div v-if="form.question_head_content !== ''"
               class="topic_content"
               v-html="$replaceAll(form.question_head_content)">
          </div>
          <div class="topic_content"
               v-html="$replaceAll(form.q2question_content)"></div>
        </div>
        <div class="bottom_border"></div>

      </div>
      <div class="box-card">
        <div class="clearfix">
          <span>题目答案：</span>
        </div>
        <div class="topic_content"
             style="color:#FF806C"
             v-html="$replaceAll(form.q2_question_answer)">
        </div>
        <div class="bottom_border"></div>

      </div>
      <div class="box-card">
        <div class="clearfix">
          <span>题目解析：</span>
        </div>
        <div style="word-wrap: break-word;"
             class="topic_content"
             v-html="$replaceAll(form.q2_question_explain)">
        </div>
        <div class="bottom_border"></div>

      </div>
    </div>
    <!-- 收藏大题的情况，循环小题 -->
    <div v-else
         class="card">
      <!-- <div class="box-card">
        <div class="clearfix">
          <span>题目：</span>
        </div>
        <div>
          <div class="topic_content"
               v-html="$replaceAll(form.q2_question_content)"></div>
        </div>
      </div> -->
      <div class="card"
           v-for="(item,index) in form"
           :key="index">
        <div class="card_head">
          <span>题目编号：{{item.question_id2}}</span> 
          <!-- 题目分数：<span style="color:#FF6147">{{item.question_score}}</span> -->

        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目内容：</span>
          </div>
          <div>
            <div class="topic_content"
                 v-html="$replaceAll(item.q2_question_content)"></div>
          </div>
          <div class="bottom_border"></div>
        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目答案：</span>
          </div>
          <div class="topic_content"
               style="color:#FF6147"
               v-html="$replaceAll(item.q2_question_answer)">
          </div>
          <div class="bottom_border"></div>
        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目解析：</span>
          </div>
          <div style="word-wrap: break-word;"
               class="topic_content"
               v-html="$replaceAll(item.q2_question_explain)">
          </div>
          <div class="bottom_border"></div>
        </div>
      </div>
    </div>
    <!-- <jiucuo ref="jiucuoRef" /> -->
  </div>
</template>

<script>
import jiucuo from '@/components/jiucuo.vue'
export default {
  data () {
    return {
      // form: [],
      a: 5,
      id: ''
    }
  },
  props: {
    form: {
      type: Array, 
      required: true // 指定该属性是必需的
    }
  },
  components: {
    jiucuo
  },
  mounted () {
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    this.id = this.$route.query.id
    // this.getInfo()
  },
  methods: {
    // getInfo () {
    //   this.form = this.$parent.form
    // },
    jiucuo () {
      let form = {
        question_id: this.form.question_id,
        comment: ''
      }
      this.$refs.jiucuoRef.form = form
      this.$refs.jiucuoRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin: auto;
  font-size: 16px;
  // padding-bottom: 20px;
  // padding-top: 20px;
  .card_head {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    background: #eff8ff;
    height: 60px;
    line-height: 60px;
    text-indent: 1em;
    position: relative;
    span {
      color: #666666;
      margin-right: 20px;
    }
    .jiucuo {
      position: absolute;
      right: 0;
      cursor: pointer;
      font-weight: bold;
      color: #3f9eff;
    }
  }
}
.el-card {
  padding: 0 15px 15px 15px;
}
.box-card {
  margin-top: 20px;
  .clearfix {
    margin-bottom: 20px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    font-size: 16px;
    font-weight: bold;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
  }
}
.bottom_border {
  width: 98%;
  height: 10px;
  // border-bottom: 1px solid #e8e8e8;
  margin-left: 20px;
}
</style>